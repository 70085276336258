import React from "react";
import "./Index.css";


const Index = () => {
  

  return (
    <div>
      <div className={`my-5 `} >
        <div className="container ">
          <div className="row pt-4 ">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <h1>Next-Generation Payment Solution</h1>
              <p className="mb-0">
                For business transactions. Enjoy the premium of fast settlement*
              </p>
              <p>
                Fasten your accounting and collection process with
                next-generation Payment Solution and fast settlement
                feature,which helps the companies to make necessary business
                decisions on time and saves around 10% of EBITA every year.
              </p>
              <div className="btn-center mt-4">
                <a className="btn-warning mr-sm-2 p-xy" href="/register">
                  Register Now
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12 hero-image">
              <img
                src="https://i.ibb.co/5Rxqq2J/img-home.webp"
                width="100%"
                className="hero-img"
                alt=""
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12 mt-100 top-100">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-4 col-12">
                  <h5 className="line-height">
                    Trusted by More than <br></br>
                    <span className="txt-warning">35+</span> Companies
                  </h5>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-6  right-border pl-5 mt-2">
                  <h4>100+</h4>
                  <p className="f-14">Happy Customer</p>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-6 mt-2">
                  <h4>100%</h4>
                  <p className="f-14">High Security</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mtb-60 py-5 benefits portfolio-scroll" id="products">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12 my-auto">
              <div className="about-img">
                <img
                  src="https://i.ibb.co/LpsSq2J/about-img.webp"
                  className="hover-item"
                  alt="benefit-img"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12 mt-5 benefits-rel">
              <h1 className="ml-5 display-5">
                The benefits that our Payment Solution provides to our
                customers.
              </h1>

              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 text-right">
                  <img src="https://i.ibb.co/j66gfPd/tick.png" alt="tick" />
                </div>
                <div className="col-lg-10 col-md-10 col-10 f-21">
                  UPI Collection Request
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 text-right">
                  <img src="https://i.ibb.co/j66gfPd/tick.png" alt="tick" />
                </div>
                <div className="col-lg-10 col-md-10 col-10 f-21">
                  UPI Intent
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 text-right">
                  <img src="https://i.ibb.co/j66gfPd/tick.png" alt="tick" />
                </div>
                <div className="col-lg-10 col-md-10 col-10 f-21">
                  Create a Dynamic QR Code
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 text-right">
                  <img src="https://i.ibb.co/j66gfPd/tick.png" alt="tick" />
                </div>
                <div className="col-lg-10 col-md-10 col-10 f-21">
                  Create a Dynamic UPI ID
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 text-right">
                  <img src="https://i.ibb.co/j66gfPd/tick.png" alt="tick" />
                </div>
                <div className="col-lg-10 col-md-10 col-10 f-21">
                  Support in getting business loans
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 text-right">
                  <img src="https://i.ibb.co/j66gfPd/tick.png" alt="tick" />
                </div>
                <div className="col-lg-10 col-md-10 col-10 f-21">
                  Guaranteed low cost of transaction-Flat fee per transaction
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-2 text-right">
                  <img src="https://i.ibb.co/j66gfPd/tick.png" alt="tick" />
                </div>
                <div className="col-lg-10 col-md-10 col-10 f-21">
                  3-stage secure processing of funds.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="payment-section pb-90 " id="service">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-12 col-md-12 col-12 mt-4">
              <h1 className="mx-2 mt-5">
                Multiple types of reporting to provide insights
              </h1>
              <p className="mx-2 my-5">
                Payuguru dashboard to be user-friendly, with interactive
                elements for a seamless experience. The goal is to empower users
                to make informed decisions based on the insights provided.
              </p>
              <div className="btn-center">
                <a className="btn-warning p-xy" href="/register">
                  Register Now
                </a>
              </div>
            </div>
            <div className="col-xl-7 col-lg-12 col-md-12 col-12 d-lg-block d-sm-none">
              <div className="row">
                <div className="col-lg-6 col-md-6 mt-5">
                  <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                    <div className="card-body p-3">
                      <img
                        src="https://i.ibb.co/L1msTDk/1.png"
                        alt="transaction-report"
                      />
                      <h5>Transaction Volume Report</h5>
                      
                      <p className="text-justify">
                        This report provides a detailed analysis of the
                        transaction volume over a specific period.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-5">
                  <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                    <div className="card-body p-3">
                      <img
                        src="https://i.ibb.co/x62S2NJ/3.png"
                        alt="transaction-value"
                      />
                      <h5>Transaction Value Report</h5>
                      
                      <p className="text-justify">
                        This report provides insights into the total value of
                        transactions processed during a given timeframe.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-5">
                  <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                    <div className="card-body p-3">
                      <img
                        src="https://i.ibb.co/G3x8nVg/2.png"
                        alt="settlement-report"
                      />
                      <h5>Settlement Report</h5>
                     
                      <p className="text-justify">
                        This report provides the details of the funds
                        transferred, settlements completed, and any pending
                        settlements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-5">
                  <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                    <div className="card-body p-3">
                      <img
                        src="https://i.ibb.co/484mBnW/4.png"
                        alt="transaction-overview"
                      />
                      <h5>Transaction Overview</h5>
                      
                      <p className="text-justify">
                        The Transaction Overview report is a comprehensive
                        summary that combines key metrics from the Transaction
                        Volume and Transaction Value reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tester_new text-center d-lg-none d-sm-block">
            <div className="container">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators indicator">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                          <div className="card-body text-left">
                            <img src="https://i.ibb.co/L1msTDk/1.png" alt="1" />
                            <h5 className="font-weight-bold">
                              Transaction Volume Report
                            </h5>
                            <hr></hr>
                            <p className="text-justify">
                              This report provides a detailed analysis of the
                              transaction volume over a specific period.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                          <div className="card-body text-left">
                            <img src="https://i.ibb.co/x62S2NJ/3.png" alt="3" />
                            <h5 className="font-weight-bold">
                              Transaction Value Report
                            </h5>
                            <hr />
                            <p className="text-justify">
                              This report provides insights into the total value
                              of transactions processed during a given
                              timeframe.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                          <div className="card-body text-left">
                            <img src="https://i.ibb.co/G3x8nVg/2.png" alt="2" />
                            <h5 className="font-weight-bold">
                              Settlement Report
                            </h5>
                            <hr />
                            <p className="text-justify">
                              This report provides the details of the funds
                              transferred, settlements completed, and any
                              pending settlements.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded-lg shadow-sm m-3 mt-80 h-270">
                          <div className="card-body text-left">
                            <img src="https://i.ibb.co/484mBnW/4.png" alt="4" />
                            <h5 className="font-weight-bold">
                              Transaction Overview
                            </h5>
                            <hr />
                            <p className="text-justify">
                              The Transaction Overview report is a comprehensive
                              summary that combines key metrics from the
                              Transaction Volume and Transaction Value reports.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="payment-section1 mtb-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center pt-3 mx-auto mt-5">
              <h1 className="headline">
                Payuguru is a next-generation payment solution,build for
                seamless payment collection
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-7 col-lg-12 col-md-12 col-12">
              <p className="text-justify">
                Payuguru simplifies the process for any company to collect
                payments effortlessly, without the hassle of dealing with
                complex setups or extensive paperwork with banks.
              </p>
              <hr />
              <article className="article group d-flex flex-row">
                <section className="content p-0">
                  <img
                    className=""
                    src="https://i.ibb.co/QPT6D16/pg.png"
                    alt="Image"
                  />
                  <h5>Making Payments Easy for Your Business</h5>
                  <p className="text-justify">
                    Payuguru simplifies the entire payment collection process,
                    making it easy for any company to receive payments
                    seamlessly. Our platform eliminates the need for complex
                    integrations and extensive paperwork with banks. With just a
                    few simple steps, businesses can set up their payment
                    collection system
                  </p>
                </section>
              </article>
              <article className="article group d-flex flex-row">
                <section className="content p-0">
                  <img
                    className=""
                    src="https://i.ibb.co/z76MmGM/nb.png"
                    alt="Image"
                  />
                  <h5 className="font-weight-bold">
                    Simple and Quick Payment Setups
                  </h5>
                  <p className="text-justify">
                    Payuguru offers a user-friendly experience, ensuring a
                    smooth setup for businesses of all sizes. Whether you're a
                    small business owner or part of a larger enterprise, our
                    platform allows you to start collecting payments quickly,
                    without the usual complexities associated with financial
                    transactions.
                  </p>
                </section>
              </article>

              <div className="m-3">
                <a className="btn-warning p-xy" href="register">
                  Register Now
                </a>
              </div>
            </div>
            <div className="col-xl-5 col-lg-12 col-md-12 col-12 my-auto">
              <div className="about-img">
                <img
                  src="https://i.ibb.co/v3ThPSh/payment-img.webp"
                  className="hover-item"
                  alt="payment-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="payment-gateway py-3 mt-80" id="industries">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-10 text-center pt-3 mx-auto">
              <h1 className="">Secure and Seamless Payments with Payuguru</h1>
            </div>
            <div className="col-lg-7 col-md-8 text-center py-3 mx-auto">
              <p className="pxy-11">
                Save more than 40% on your payment collections by opting for
                flat charges with Payuguru instead of percentage fees.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-8 text-center pb-5 mx-auto">
              <h4>Commonly Used For</h4>
            </div>
          </div>
          <div className="row mb-5 mt-0">
            <div className="col-lg-6 col-md-12 col-12 mt-5 d-lg-block d-sm-none">
              <div className="card border-0 rounded shadow-sm mt-2 card-bg">
              <div className="card-body py-auto" >
                  <div className="row mt-0 ">
                    <div className="col-lg-9 col-md-9 col-12">
                      <h5 className="">Education Services</h5>
                      <p className="text-justify">
                        Now you can Use for Educational institutions often
                        integrate UPI for fee payments. Students and parents can
                        conveniently pay tuition fees, exam fees, and other
                        charges using UPI-enabled apps.
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 my-auto text-center">
                      <img
                        src="https://i.ibb.co/bBVyrCd/education-service.png"
                        alt="education-service"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 d-lg-block d-sm-none">
              <div className="card border-0 rounded shadow-sm mt-2 card-bg">
                <div className="card-body py-auto">
                  <div className="row mt-0">
                    <div className="col-lg-9 col-md-9 col-12">
                      <h5 className="">
                        Travel and Hospitality
                      </h5>
                      <p className="text-justify">
                        Now you can Use Payuguru Collection UPI for booking
                        flights, hotels, or planning exciting adventures, UPI
                        simplifies payments. Travel agencies now leverage our
                        UPI for secure and making the entire booking process
                        easy.
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 my-auto text-center">
                      <img
                        src="https://i.ibb.co/BLC3DDk/travel.png"
                        alt="travel"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 d-lg-block d-sm-none">
              <div className="card border-0 rounded shadow-sm mt-2 card-bg">
                <div className="card-body py-auto">
                  <div className="row mt-0">
                    <div className="col-lg-9 col-md-9 col-12">
                      <h5 className="">
                        Entertainment and Streaming Services{" "}
                      </h5>
                      <p className="text-justify">
                        Now you can Use Payuguru Collection UPI for subscribing
                        to streaming services, purchasing digital content, and
                        accessing entertainment platforms.
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 my-auto text-center">
                      <img src="https://i.ibb.co/GHGg4cf/game.png" alt="game" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 d-lg-block d-sm-none">
              <div className="card border-0 rounded shadow-sm mt-2 card-bg">
                <div className="card-body py-auto">
                  <div className="row mt-0">
                    <div className="col-lg-9 col-md-9 col-12">
                      <h5 className="">
                        Healthcare and Wellness{" "}
                      </h5>
                      <p className="text-justify">
                        Now you can Use Payuguru Collection UPI for healthcare
                        industry for various purposes, including doctor
                        consultations, lab tests, and purchasing medicines
                        online.
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 my-auto text-center">
                      <img
                        src="https://i.ibb.co/ys4Hsk8/health.png"
                        alt="health"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 d-lg-block d-sm-none">
              <div className="card border-0 rounded shadow-sm mt-2 card-bg">
                <div className="card-body py-auto">
                  <div className="row mt-0">
                    <div className="col-lg-9 col-md-9 col-12">
                      <h5 className="">Government Services </h5>
                      <p className="text-justify">
                        Now you can Use Payuguru Collection UPI for Government
                        services and payments, such as taxes, fees, and fines,
                        can be settled using our UPI.
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 my-auto text-center">
                      <img
                        src="https://i.ibb.co/Y73RQy0/service.png"
                        alt="service"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 d-lg-block d-sm-none">
              <div className="card border-0 rounded shadow-sm mt-2 card-bg">
                <div className="card-body py-auto">
                  <div className="row mt-0">
                    <div className="col-lg-9 col-md-9 col-12">
                      <h5 className="">
                        Utilities and Bill Payment
                      </h5>
                      <p className="text-justify">
                        Now you can use Payuguru Collection UPI to collect
                        utility bills, including electricity, water, gas, and
                        internet bills, offering users a convenient way to
                        manage and settle their recurring payments.
                      </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 my-auto text-center">
                      <img
                        src="https://i.ibb.co/wKJ0c4g/payment.png"
                        alt="payment"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tester_new text-center d-lg-none d-sm-block mt-64">
            <div className="container">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators indicator carousel-indicators1">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="5"
                  ></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded shadow-sm mt-2">
                          <div className="card-body text-left">
                            <div className="row mt-0">
                              <div className="col-lg-12 col-md-12 col-12">
                                <img
                                  src="https://i.ibb.co/bBVyrCd/education-service.png"
                                  alt="education-service"
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-12">
                                <h5 className="font-weight-bold">
                                  Education Services
                                </h5>
                                <p className="text-justify">
                                  Now you can Use for Educational institutions
                                  often integrate UPI for fee payments. Students
                                  and parents can conveniently pay tuition fees,
                                  exam fees, and other charges using UPI-enabled
                                  apps.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded shadow-sm mt-2">
                          <div className="card-body text-left">
                            <div className="row mt-0">
                              <div className="col-lg-12 col-md-12 col-12 mt-3 ">
                                <img
                                  src="https://i.ibb.co/BLC3DDk/travel.png"
                                  alt="travel"
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-12">
                                <h5 className="font-weight-bold">
                                  Travel and Hospitality
                                </h5>
                                <p className="text-justify">
                                  Now you can Use Payuguru Collection UPI for
                                  booking flights, hotels, or planning exciting
                                  adventures, UPI simplifies payments. Travel
                                  agencies now leverage our UPI for secure and
                                  making the entire booking process easy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded shadow-sm mt-2">
                          <div className="card-body text-left">
                            <div className="row mt-0">
                              <div className="col-lg-12 col-md-12 col-12">
                                <img
                                  src="https://i.ibb.co/GHGg4cf/game.png"
                                  alt="game"
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-12">
                                <h5 className="font-weight-bold">
                                  Entertainment and Streaming Services{" "}
                                </h5>
                                <p className="text-justify">
                                  Now you can Use Payuguru Collection UPI for
                                  subscribing to streaming services, purchasing
                                  digital content, and accessing entertainment
                                  platforms.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded shadow-sm mt-2">
                          <div className="card-body text-left">
                            <div className="row mt-0">
                              <div className="col-lg-12 col-md-12 col-12">
                                <img
                                  src="https://i.ibb.co/ys4Hsk8/health.png"
                                  alt="health"
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-12">
                                <h5 className="font-weight-bold">
                                  Healthcare and Wellness{" "}
                                </h5>
                                <p className="text-justify">
                                  Now you can Use Payuguru Collection UPI for
                                  healthcare industry for various purposes,
                                  including doctor consultations, lab tests, and
                                  purchasing medicines online.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded shadow-sm mt-2">
                          <div className="card-body text-left">
                            <div className="row mt-0">
                              <div className="col-lg-12 col-md-12 col-12 mt-0">
                                <img
                                  src="https://i.ibb.co/Y73RQy0/service.png"
                                  alt="service"
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-12">
                                <h5 className="font-weight-bold">
                                  Government Services{" "}
                                </h5>
                                <p className="text-justify">
                                  Now you can Use Payuguru Collection UPI for
                                  Government services and payments, such as
                                  taxes, fees, and fines, can be settled using
                                  our UPI.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-lg-3 col-md-2 col-12"></div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="card border-0 rounded shadow-sm mt-2">
                          <div className="card-body text-left">
                            <div className="row mt-0">
                              <div className="col-lg-12 col-md-12 col-12">
                                <img
                                  src="https://i.ibb.co/wKJ0c4g/payment.png"
                                  alt="payment"
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-12">
                                <h5 className="font-weight-bold">
                                  Utilities and Bill Payments
                                </h5>
                                <p className="text-justify">
                                  Now you can use Payuguru Collection UPI to
                                  collect utility bills, including electricity,
                                  water, gas, and internet bills, offering users
                                  a convenient way to manage and settle their
                                  recurring payments.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2 col-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mb-75">
            <p className="f-24">
              Why to pay more! When you can save more than 40% with us!
            </p>
          </div>
        </div>
      </section>
      <section className="pricing py-3 mtb-40" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-md-9 text-center mx-auto">
              <h1 className="">Our Pricing</h1>
              <p className="pxy-11">
                Unlock savings and efficiency with our pricing. Why pay more
                when you can save over 40% with us? Choose smart, choose
                affordability with Payuguru.
              </p>
            </div>
          </div>
          <div className="row mb-5 ">
            <div className="col-lg-12 col-md-12 col-12 mt-5">
              <div className="card card__content shadow mt-2">
                <div className="col-md-9 text-center mx-auto upi-content">
                  <h2 className="">
                    <img
                      src="https://i.ibb.co/bzD17x7/collect.png"
                      alt="collect"
                    />
                    UPI Collection/QR Code
                  </h2>
                </div>
                <div className="card__pricing">
                  <div className="card__pricing-number">
                    <span className="card__pricing-symbol">Standard Price</span>
                  </div>
                </div>
                <div className="card-body mt-5">
                  <div className="row mt-0 upi-card">
                    <div className="col-lg-6 col-md-6 col-12 border-right-lg my-auto">
                      <table className="table table-responsive border-bottom-sm  ml-60">
                        <tbody>
                          <tr>
                            <th>UPI Collection Request</th>
                          </tr>
                          <tr>
                            <th>UPI Intent</th>
                          </tr>
                          <tr>
                            <th>Create a Dynamic QR Code</th>
                          </tr>
                          <tr>
                            <th>Create a Dynamic UPI ID</th>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 my-auto">
                      <table className="table table-responsive ml-60 ">
                        <tbody>
                          <tr>
                            <th>Flat Rs-10/-* Per Transaction</th>
                          </tr>

                          <tr>
                            <th>GST as applicable</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center my-4">
                      <a className="btn-warning p-xy m-0" href="/register">
                        Register Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq payment-gateway py-5 faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-9 text-center py-5 mx-auto mt-40">
              <h1 className="">Frequently Asked Questions</h1>
              <p className="pxy-11">
                Want to know more about Payuguru? These FAQs break down
                everything you need to know about our platform.
              </p>
            </div>
          </div>
          <div className="accordion col-md-9 mx-auto">
            <div className="accordion-item">
              <button
                id="accordion-button-1"
                aria-expanded="false"
                fdprocessedid="9pyylo"
              >
                <span className="accordion-title">Why Payuguru ?</span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div className="accordion-content">
                <p>
                  Payuguru Explore the benefits of flat charges vs.percentage
                  charges, save more than 40% fee charges with pay u guru.
                </p>
              </div>
            </div>
            <div className="accordion-item">
              <button id="accordion-button-2" aria-expanded="false">
                <span className="accordion-title">
                  What can I use Payuguru for?
                </span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div className="accordion-content">
                <p>
                  Payuguru helps with UPI collection of payments through
                  developer friendly APIs.<br></br>
                  1.UPI collection Request<br></br>
                  2.UPI Intent<br></br>
                  3.Dynamic UPI/QR Code<br></br>
                  4.Create Dynamic UPI ID
                </p>
              </div>
            </div>

            <div className="accordion-item">
              <button id="accordion-button-3" aria-expanded="false">
                <span className="accordion-title">
                  Why should I choose Payuguru?
                </span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div className="accordion-content">
                <p>
                  Payuguru offers the highest success rate for UPI Collection
                  transactions, featuring flat charges and the fastest
                  settlement process. With no set-up costs, our user-friendly
                  API ensures ease of use, making it a seamless experience for
                  our customers.
                </p>
              </div>
            </div>
            <div className="accordion-item">
              <button id="accordion-button-4" aria-expanded="false">
                <span className="accordion-title">
                  {" "}
                  Is the pricing negotiable?
                </span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div className="accordion-content">
                <p>
                  Certainly, we offer customized pricing structures based on
                  transaction volumes. For further inquiries or to discuss your
                  specific requirements, please don't hesitate to contact us via
                  email at info@payu.guru or leave us a message through our
                  Contact Us page. We look forward to addressing your needs
                  promptly and professionally.
                </p>
              </div>
            </div>
            <div className="accordion-item">
              <button id="accordion-button-5" aria-expanded="false">
                <span className="accordion-title">How do i Use Payuguru?</span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div className="accordion-content">
                <p>
                  1.Sign up on Payu.guru, and your account will be open
                  instantly.<br></br>
                  2.Once registered, log in to your dashboard, complete the KYC
                  process, and find your API key in the settings section.
                  <br></br>
                  3.Explore our API documentation to initiate the integration
                  process.<br></br>
                  4.For any inquiries, please contact us at info@payu.guru.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Index;
